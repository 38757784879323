import React, { Children, cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Item from './Item';

// Styles
import styles from './Accordion.styl';

const cx = classNames.bind(styles);

export default function Accordion(props) {
  const { selectedIdx, children, className, setOuterState } = props;

  // Индекс активной секции
  const [stateSelectedIdx, setStateSelectedIdx] = useState(selectedIdx >= 0 ? selectedIdx : -1);

  /**
   * Переключаем секции
   */
  const toggle = tabIndex => {
    const newSelectedIdx = stateSelectedIdx === tabIndex ? -1 : tabIndex;

    // если мы передали эту функцию, значит нам нужно запомнить снаружи последний индекс
    setOuterState(newSelectedIdx);

    setStateSelectedIdx(newSelectedIdx);
  };

  /**
   * Добавляем в каждую секцию ее индекс,
   * обработчик события клика и флаг активности
   */
  const getAdditionalProps = (index, componentProps) => ({
    ...componentProps,
    handleClick: () => toggle(index),
    selected: index === stateSelectedIdx,
  });

  /**
   * Клонируем секции,
   * добавляя дополнительные свойства
   */
  const getChildrenItemsWithProps = () =>
    Children.map(
      children,
      (child, index) => child && cloneElement(child, getAdditionalProps(index, child.props)),
    );

  return <div className={cx('Accordion', className)}>{getChildrenItemsWithProps()}</div>;
}

Accordion.Item = Item;

Accordion.defaultProps = {
  className: '',
  selectedIdx: null,
  setOuterState: () => {
  },
};

Accordion.propTypes = {
  className: PropTypes.string,
  selectedIdx: PropTypes.number,
  setOuterState: PropTypes.func,
  children: PropTypes.any.isRequired,
};
