import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import FirstScene from '../scenes/FirstScene';
import SecondScene from '../scenes/SecondScene';
import ThirdScene from '../scenes/ThirdScene';
import WarningModal from '../partials/WarningModal';

// Style
import styles from './MobileView.styl';

const cx = classNames.bind(styles);

export default function MobileView(props) {

  const { className, fromProduct, warning, clearWarning, selectedIngredients } = props;

  const [scene, setScene] = useState(fromProduct === 1 ? 2 : 1);

  const nextScene = () => {
    // this.setState(prevState => ({ scene: prevState.scene + 1 }));
    setScene(scene + 1)
  };

  const prevScene = () => {
    // this.setState(prevState => ({ scene: prevState.scene - 1 }));
    setScene(scene - 1)
  };

  return (
    <div className={cx('Constructor', className)}>
      {scene === 1 && <FirstScene {...props} onNext={nextScene} />}
      {scene === 2 && (
        <SecondScene
          {...props}
          onNext={nextScene}
          onPrev={prevScene}
          warning={warning}
          selectedIngredients={selectedIngredients}
        />
      )}
      {scene === 3 && (
        <ThirdScene {...props} onNext={nextScene} onPrev={prevScene} />
      )}
      <WarningModal isOpen={warning} warning={warning} onClose={clearWarning} />
    </div>
  );
}
MobileView.propTypes = {
  className: PropTypes.string,
  variation: PropTypes.object.isRequired,
  ingredients: PropTypes.object,
  addPortion: PropTypes.func.isRequired,
  removePortion: PropTypes.func.isRequired,
  removeIngredient: PropTypes.func.isRequired,
  fromProduct: PropTypes.number,
  warning: PropTypes.string,
  clearWarning: PropTypes.func,
  isItSauce: PropTypes.func,
  selectedIngredients: PropTypes.object.isRequired
};
