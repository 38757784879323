import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Style
import styles from './Tabs.styl';

const cx = classNames.bind(styles);

export default function Tab(props) {

  const { title, tabIndex, selected, disabled } = props;

  const handleClick = () => {
    // Вызываем родительский метод для переключения вкладки
    props.handleClick(tabIndex);
  };
  return (
    <li
      className={cx('Tabs__item', {
        'Tabs__item-selected': selected,
        'Tabs__item-disabled': disabled
      })}
      onClick={handleClick}>
      {title}
    </li>
  );
}
Tab.propTypes = {
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  tabIndex: PropTypes.number,
  selected: PropTypes.bool,
  disabled: PropTypes.bool
};
