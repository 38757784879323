import { createSelector } from 'reselect';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import { variationSelector } from '../catalog/selectors';
import { CRUST_EXTRA, CRUST_EXTRA_EN, CRUST_EXTRA_PL } from '../../../utils/constants';

export const includeIngredientsSelector = createSelector(variationSelector, variation => {
  return _get(variation, 'include_ingredients', []).map(ingredient => ({
    ...ingredient,
    included: true,
  }));
});

export const allIngredientsSelector = createSelector(
  [variationSelector, includeIngredientsSelector],
  (variation, includeIngredients) => {
    const availableIngredients = _get(variation, 'available_ingredients', []);

    return [...availableIngredients, ...includeIngredients];
  },
);

export const ingredientsGroupByTypeSelector = createSelector(
  [allIngredientsSelector, (state, props) => props.intl],
  (ingredients, intl) => {
    const ingredientsTypes = _groupBy(ingredients, 'type') || {};

    // если в списке есть соус, то ставим его на первое место
    if (Object.keys(ingredientsTypes)?.includes(intl.formatMessage({ id: 'sauce' }))) {
      return {
        [intl.formatMessage({ id: 'sauce' })]:
          ingredientsTypes[
          intl.formatMessage({ id: 'sauce' })
        ],
        ...ingredientsTypes,
      };
    }

    return ingredientsTypes;
  },
);

export const addedIngredientsSelector = createSelector(variationSelector, variation => {
  const availableIngredients = _get(variation, 'available_ingredients', []);

  return availableIngredients.filter(ingredient => ingredient.count > 0);
});

export const addedIngredientsTotalPriceSelector = createSelector(
  addedIngredientsSelector,
  ingredients => {
    return ingredients.reduce(
      (acc, ingredient) => acc + Number(ingredient.price) * ingredient.count,
      0,
    );
  },
);

export const includeIngredientsTotalPriceSelector = createSelector(
  includeIngredientsSelector,
  ingredients => {
    return ingredients
      .filter(ingredient => ingredient.count > 1)
      .reduce((acc, ingredient) => acc + Number(ingredient.price), 0);
  },
);

export const allIngredientsTotalPriceSelector = createSelector(
  [addedIngredientsTotalPriceSelector, includeIngredientsTotalPriceSelector],
  (addedPrice, includePrice) => addedPrice + includePrice,
);

export const totalCountSelector = createSelector(
  [includeIngredientsSelector, addedIngredientsSelector],
  (includeIngredients, addedIngredients) => {
    return [...includeIngredients, ...addedIngredients].reduce(
      (acc, ingredient) =>
        ingredient.type === CRUST_EXTRA || ingredient.type === CRUST_EXTRA_EN || ingredient.type === CRUST_EXTRA_PL
          ? acc
          : acc + Number(ingredient.count),
      0,
    );
  },
);

export const selectedIngredientsSelector = createSelector(
  [includeIngredientsSelector, addedIngredientsSelector],
  (includeIngredients, addedIngredients) => {
    return [...includeIngredients, ...addedIngredients].filter(ingredient => ingredient.count > 0);
  },
);
