import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';

// Components
import Modal from '../../../Modal';
import Button from '../../../Button';

// Style
import styles from './WarningModal.styl';

const cx = classNames.bind(styles);

const WarningModal = ({ onClose, isOpen, warning }) => {

  const [message, setMessage] = useState('constructor.message.moreThan');

  useEffect(() => {
    if (warning) {
      setMessage(warning);
    }
  }, [warning]);

  return (
    <Modal className={cx('WarningModal')} isOpen={isOpen} onClose={onClose}>
      <div className={cx('WarningModal__text')}>
        <FormattedMessage id={message} />
      </div>
      <Button className={cx('WarningModal__button')} onClick={onClose}>
        <FormattedMessage id="button.understandably" />
      </Button>
    </Modal>
  );
};
WarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  warning: PropTypes.string
};

export default WarningModal;
