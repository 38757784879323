import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';

// Components
import Modal from '../../../Modal';
import Heading from '../../../Heading';
import Amount from '../../../Amount';
import Button from '../../../Button';

// Style
import styles from './RemovePortionModal.styl';

const cx = classNames.bind(styles);

export default function RemovePortionModal(props) {
  const { isOpen, name, image, price, count, onClose, onRemove, onRemovePortion, not_for_delete = false } = props;
  const isUndeleted = not_for_delete;

  const remove = () => {
    onRemove();
    onClose();
  };

  const removePortion = () => {
    onRemovePortion();
    onClose();
  };

  return (
    <Modal className={cx('RemovePortionModal')} isOpen={isOpen} onClose={onClose}>
      <div className={cx('RemovePortionModal__header')}>
        <FormattedMessage id="constructor.removeInrgredientsModal.title" values={{ name }}>
          {title => (
            <Heading className={cx('RemovePortionModal__title')} tagName="div">
              {title}
            </Heading>
          )}
        </FormattedMessage>
        <div className={cx('RemovePortionModal__close')} onClick={onClose}>
          <FormattedMessage id="button.cancel" />
        </div>
      </div>
      <div className={cx('RemovePortionModal__body')}>
        <div className={cx('RemovePortionModal__ingredient')}>
          <figure className={cx('RemovePortionModal__ingredient-image')}>
            <img src={image} alt="" />
          </figure>
          <Heading className={cx('RemovePortionModal__ingredient-name')} level={5}>
            {name}
          </Heading>
          <Amount value={price} size="s" />
        </div>
      </div>
      {count > 1 && !isUndeleted && (
        <div className={cx('RemovePortionModal__actions')}>
          <Button
            className={cx('RemovePortionModal__actions__item')}
            onClick={removePortion}
            danger
          >
            <FormattedMessage id="constructor.button.removePortion" values={{ count: 1 }} />
          </Button>
          <Button
            className={cx('RemovePortionModal__actions__item')}
            onClick={remove}
            danger
          >
            <FormattedMessage id="constructor.button.removePortion" values={{ count: 2 }} />
          </Button>
        </div>
      )}
      {count == 1 && !isUndeleted && (
        <Button className={cx('RemovePortionModal__button')} onClick={remove} danger>
          <FormattedMessage id="button.remove" />
        </Button>
      )}
      {count > 1 && isUndeleted && (
        <div className={cx('RemovePortionModal__actions')}>
          <Button
            className={cx('RemovePortionModal__actions__item')}
            onClick={removePortion}
            danger
          >
            <FormattedMessage id="constructor.button.removePortion" values={{ count: 1 }} />
          </Button>
        </div>
      )}
    </Modal>
  );
}
RemovePortionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRemovePortion: PropTypes.func.isRequired,
};
