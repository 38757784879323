import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Paper from '../../Paper';
import Amount from '../../Amount';
import Button from '../../Button';
import Heading from '../../Heading/DesktopView';
import PizzaCard from '../partials/PizzaCard';
import IngredientCard from '../partials/IngredientCard';
import Tabs, { Tab } from '../partials/Tabs';
import Composition from '../partials/Composition';

// Style
import styles from './DesktopView.styl';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);

export default function DesktopView(props) {
  const {
    className, ingredients, variation, addToCart, totalPrice, addPortion,
    removePortion, removeIngredient, warning, activeStuffedCrust,
    isSauceSelected, isItSauce, selectedIngredients, isItSauceAfterTheOven,
  } = props;
  const [isAdding, setIsAdding] = useState(false);

  const onAddToCart = async () => {
    setIsAdding(true);
    await addToCart();
    setIsAdding(false);
  };
  const { isTablet } = useSelector(state => state.responsive);

  return (
    <Paper className={cx('Constructor', className)}>
      <div className={cx('Constructor__info')} data-test-id="constructor_info">
        <PizzaCard {...variation} {...props} className={cx('Constructor__pizza-card')} />
        <div className={cx('Constructor__footer')}>
          <Button
            onClick={onAddToCart}
            isLoading={isAdding}
          >
            <FormattedMessage id="button.inCart" />
          </Button>
          <div className={cx('Constructor__price')}>
            <p className={cx('Constructor__price-label')}>
              <FormattedMessage id="label.total" />:
            </p>
            <Amount value={totalPrice} />
          </div>
        </div>
        <Composition
          {...variation}
          {...props}
          stuffed_crust={activeStuffedCrust}
          className={cx('Constructor__composition')}
        />

      </div>
      <div className={cx('Constructor__main')} data-test-id="constructor_main">
        <div className={cx('Constructor__header')}>
          <Heading className={cx('Constructor__title')} level={2} tagName="h1">
            {/*<FormattedMessage id="constructor.title" />*/}
          </Heading>
          <small className={cx('Constructor__note')}>
            <FormattedMessage id="constructor.note" />
          </small>
        </div>
        <Tabs>
          {Object.keys(ingredients).map(type => (
            <Tab key={type} disabled={!isSauceSelected} isItSauce={isItSauce} title={type}>
              <div className={cx('Constructor__ingredients')} data-test-id="constructor_ingredients">
                {ingredients[type].map(ingredient => (
                  <IngredientCard
                    key={ingredient.id}
                    {...ingredient}
                    className={cx('Constructor__ingredient')}
                    addPortion={addPortion}
                    removePortion={removePortion}
                    onRemove={removeIngredient}
                    isItSauce={isItSauce}
                    isItSauceAfterTheOven={isItSauceAfterTheOven}
                    selectedIngredients={selectedIngredients}
                    isWarning={!!warning}
                  />
                ))}
              </div>
            </Tab>
          ))}
        </Tabs>
        {warning && (
          <div className={cx('Constructor__warning')}>
            <FormattedMessage id={warning} />
          </div>
        )}
      </div>
    </Paper>
  );
}

DesktopView.propTypes = {
  className: PropTypes.string,
  ingredients: PropTypes.object,
  variation: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
  addPortion: PropTypes.func.isRequired,
  removePortion: PropTypes.func.isRequired,
  removeIngredient: PropTypes.func.isRequired,
  warning: PropTypes.bool.isRequired,
  activeStuffedCrust: PropTypes.string.isRequired,
  isSauceSelected: PropTypes.bool.isRequired,
  isItSauce: PropTypes.func.isRequired,
  selectedIngredients: PropTypes.object.isRequired
};

