import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import PortionSwitcher from '../../PortionSwitcher';
import RemovePortionModal from '../../RemovePortionModal';
import Amount from '../../../../Amount';

// Icons
import RemoveIcon from '../../../../../icons/close.svg';

// Style
import styles from './MobileView.styl';
import { CRUST_EXTRA, CRUST_EXTRA_EN, CRUST_EXTRA_PL } from '../../../../../utils/constants';

const cx = classNames.bind(styles);

export default function MobileView(props) {

  const {
    className, name, count, addPortion, removePortion,
    remove, totalPrice, type, isItSauce, isItSauceAfterTheOven, not_for_delete = false,
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const showRemoveModal = () => {
    setModalIsOpen(true);
  };

  const hideRemoveModal = () => {
    setModalIsOpen(false);
  };

  const isCrust = type === CRUST_EXTRA || type === CRUST_EXTRA_EN || type === CRUST_EXTRA_PL;
  const isUndeleted = not_for_delete;
  return (
    <div className={cx('IncludeIngredient', className)}>
      {!isUndeleted ?
        <div
          className={cx('IncludeIngredient__cell', 'IncludeIngredient__cell_remove')}
          onClick={(!isItSauce(type) && !isItSauceAfterTheOven(type)) && showRemoveModal}
        >
          <div className={cx('IncludeIngredient__remove')}>
            {(!isItSauce(type) && !isItSauceAfterTheOven(type)) && <RemoveIcon width={12} height={12} />}
          </div>
        </div>
        : <div
          className={cx('IncludeIngredient__cell', 'IncludeIngredient__cell_remove')}
        >
        </div>
      }
      <div className={cx('IncludeIngredient__cell', 'IncludeIngredient__cell_info')}>
        <div className={cx('IncludeIngredient__name')}>{name}</div>
        <Amount value={totalPrice} />
      </div>
      {(!isItSauce(type) && !isItSauceAfterTheOven(type)) && (
        <>
          <div className={cx('IncludeIngredient__cell', 'IncludeIngredient__cell_portion')}>
            <PortionSwitcher
              count={count}
              onAdd={addPortion}
              onRemove={removePortion}
              isCrust={isCrust}
            />
          </div>
          <RemovePortionModal
            {...props}
            isOpen={modalIsOpen}
            onRemove={remove}
            onRemovePortion={removePortion}
            onClose={hideRemoveModal}
          />
        </>
      )}
    </div>
  );
}
MobileView.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  addPortion: PropTypes.func.isRequired,
  removePortion: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  totalPrice: PropTypes.number,
  type: PropTypes.string,
  isItSauce: PropTypes.func,
};
