import React, { Children, cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Style
import styles from './Tabs.styl';

const cx = classNames.bind(styles);

export default function Tabs(props) {

  const { children } = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  /**
   * Переключаем вкладки
   */
  const handleTabClick = (tabIndex) => {
    setSelectedTabIndex(tabIndex);
  };

  const getAdditionalProps = (index, props) => ({
    ...props,
    handleClick: handleTabClick,
    tabIndex: index,
    selected: index === selectedTabIndex,
    disabled: props.isItSauce(props.title) ? false : props.disabled
  });

  /**
   * Клонируем children(компоненты <Tab />),
   *  добавляя в каждый нужные нам props:
   * - обработчик события клика;
   * - индекс вкладки
   * - флаг активности.
   */
  const getChildrenTabsWithProps = () => {
    return Children.map(children, (child, index) =>
      cloneElement(child, getAdditionalProps(index, child.props))
    );
  };

  /**
   * Рендерим контент активной вкладки
   */
  const getActiveTabContent = () => {
    const currentChildren = children[selectedTabIndex];
    return currentChildren ? currentChildren.props.children : null;
  };

  return (
    <div className={cx('Tabs')}>
      <ul className={cx('Tabs__list')}>{getChildrenTabsWithProps()}</ul>
      <div className={cx('Tabs__content')}>{getActiveTabContent()}</div>
    </div>
  );
}
Tabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};
