import React from 'react';
import PropTypes from 'prop-types';
import _join from 'lodash/join';
import _groupBy from 'lodash/groupBy';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import { useIntl } from 'react-intl';
import { isPizza } from '../../utils/catalog';

export default function VariationInfo(props) {
  const { className, size, stuffed_crust, kind, combo_box_items, type, style } = props;

  const intl = useIntl();

  const makeInfo = () => {
    const newKind = isPizza({ kind }) ? kind.name : '';
    const newSize = `${size?.value || ''}${size?.value && size?.unit ? '&nbsp;' : ''}${
      size?.unit || ''
    }`;
    const cheeseBorder =
      isPizza({ kind }) && stuffed_crust !== 'none'
        ? intl.formatMessage({ id: `pizza.stuffedCrust.${stuffed_crust}` })
        : '';
    const values = [newKind, newSize, cheeseBorder];

    return _join(_compact(values), ', ');
  };

  const makeComboboxInfo = () => {
    const categories = _groupBy(combo_box_items, 'category');

    return _join(
      _map(categories, (category, index) => `${index}&nbsp;(${category.length})`),
      ', ',
    );
  };

  const info = type !== 'combo' ? makeInfo() : makeComboboxInfo();

  if (!info.replace('&nbsp;', '').trim()) {
    return null;
  }

  return <div className={className} dangerouslySetInnerHTML={{ __html: info }} style={style} />;
}

VariationInfo.defaultProps = {
  className: '',
  stuffed_crust: 'none',
};

VariationInfo.propTypes = {
  className: PropTypes.string,
  size: PropTypes.object.isRequired,
  kind: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  combo_box_items: PropTypes.array.isRequired,
  stuffed_crust: PropTypes.string,
};
