import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';

import Paper from '../Paper';
import Heading from '../Heading';
import Button from '../Button';

import CartIcon from '../../icons/cart.svg';

// Router
import { paths, getRoute } from '../../entry/routes';

import styles from './CreatePizzaSuccessMessage.styl';
import { setOpenedProductCard } from '../../state/modules/ui/actions';
import { useDispatch } from 'react-redux';

const cx = classNames.bind(styles);

const CreatePizzaSuccessMessage = ({ className, name, mod, children }) => {
  const dispatch = useDispatch()

  return (
    <Paper
      className={cx('CreatePizzaSuccessMessage', className, {
        [`CreatePizzaSuccessMessage_${mod}`]: mod,
      })}
    >
      <div className={cx('CreatePizzaSuccessMessage__content')} data-test-id="create_pizza">
        <CartIcon className={cx('CreatePizzaSuccessMessage__icon')} width={32} height={32} />
        {name && (
          <Heading level={2} tagName="div" className={cx('CreatePizzaSuccessMessage__title')}>
            <FormattedMessage id="pizza.successMessage.title" values={{ name }} />
          </Heading>
        )}
        <div className={cx('CreatePizzaSuccessMessage__actions')}>
          <div   className={cx('CreatePizzaSuccessMessage__button')} onClick={() => {
            dispatch(setOpenedProductCard(null));
          }}>
            <Button
              className={cx('CreatePizzaSuccessMessage__button')}
              secondary
              to={getRoute(paths.home)}
            >
              <FormattedMessage id="button.goToMenu" />
            </Button>
          </div>

          <Button className={cx('CreatePizzaSuccessMessage__button')} to={getRoute(paths.order)}>
            <FormattedMessage id="button.placeOrder" />
          </Button>
        </div>
        {children}
      </div>
    </Paper>
  );
};

CreatePizzaSuccessMessage.defaultProps = {
  className: '',
  children: '',
  name: '',
};

CreatePizzaSuccessMessage.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  name: PropTypes.string,
  mod: PropTypes.string,
};

export default CreatePizzaSuccessMessage;
