import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Scene from '../../../Scene';
import Amount from '../../../Amount';
import Button from '../../../Button';

// Partials
import NavBar from '../../partials/NavBar';
import Composition from '../../partials/Composition';

// Style
import styles from './ThirdScene.styl';

const cx = classNames.bind(styles);

export default function ThirdScene(props) {
  const {
    className, mod, onPrev, addToCart, totalPrice, variation,
    totalCount, isPhone, isTablet
  } = props;

  const [isAdding, setIsAdding] = useState(false);

  const renderNavbar = () => {
    return (
      <FormattedMessage id="constructor.scene3.back">
        {title => <NavBar className={cx('ThirdScene__navbar')} backText={title} onBack={onPrev} />}
      </FormattedMessage>
    );
  };

  const onAddToCart = async () => {
    setIsAdding(true);
    await addToCart();
    setIsAdding(false);
  };

  const renderFooter = () => {
    return (
      <div className={cx('ThirdScene__footer')}>
        <div className={cx('ThirdScene__info')}>
          <div className={cx('ThirdScene__count')}>
            <FormattedMessage id="constructor.ingredientsCount" values={{ count: totalCount }} />
          </div>
          <div className={cx('ThirdScene__total')}>
            <FormattedMessage id="label.total" />:{' '}
            <Amount className={cx('ThirdScene__amount')} value={totalPrice} />
          </div>
        </div>
        {!isPhone ? (
          <Button
            className={cx('ThirdScene__button', 'ThirdScene__button_back')}
            secondary
            onClick={onPrev}
          >
            <FormattedMessage id="button.back" />
          </Button>
        ) : null}
        <Button
          className={cx('ThirdScene__button')}
          onClick={onAddToCart}
          isLoading={isAdding}
        >
          <FormattedMessage id="button.inCart" />
        </Button>
      </div>
    );
  };
  return (
    <Scene
      className={cx('ThirdScene', className, {
        ThirdScene_tablet: isTablet
      })}
      renderNavbar={renderNavbar}
      renderFooter={renderFooter}
    >
      <Composition mod={mod} {...props} {...variation} />
    </Scene>
  );
}
ThirdScene.propTypes = {
  className: PropTypes.string,
  mod: PropTypes.string,
  onPrev: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
  variation: PropTypes.object,
  totalCount: PropTypes.number.isRequired,
  isPhone: PropTypes.bool,
  isTablet: PropTypes.bool,
  isItSauce: PropTypes.func
};
