import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import PizzaSelector from '../../../PizzaSelector';

// Icons
import EditIcon from '../../../../icons/edit.svg';
import placeholderImg from './placeholder.svg';

// Style
import styles from './PizzaCard.styl';

const cx = classNames.bind(styles);

export default function PizzaCard(props) {
  const {
    className, name, alias, customName, image_cart, image_list_webp, doughTypes, sizes, currentDough,
    currentSize, stuffedCrustIsAvailable, stuffedCrustIsAdd, activeStuffedCrust, stuffedCrustsAvailable,
    changeDough, changeSize, toggleStuffedCrust, selectStuffedCrust, setCustomName, initialStuffedCrust,
  } = props;

  const handleChangeName = e => {
    return setCustomName(e.target.value);
  };
  const isConstructor = alias === 'sozday' || alias === 'sozday-vegan'

  return (
    <div className={cx('PizzaCard', className)}>
      <figure className={cx('PizzaCard__image')}>
        <img src={image_list_webp} alt="" />
      </figure>
      <div className={cx('PizzaCard__field')}>
        {isConstructor ?
          <FormattedMessage id="constructor.field.name.placeholder">
            {placeholder => (
              <input
                type="text"
                onChange={handleChangeName}
                value={customName}
                placeholder={isConstructor && placeholder}
              />
            )}
          </FormattedMessage> : (<div className={cx('PizzaCard__text')}>
            {name}
          </div>)}
        {isConstructor && <div className={cx('PizzaCard__field-edit')}>
          <EditIcon width={24} height={24} />
        </div>}
      </div>
      <PizzaSelector
        className={cx('PizzaCard__selector')}
        doughTypes={doughTypes}
        sizes={sizes}
        currentSize={currentSize}
        currentDough={currentDough}
        changeSize={changeSize}
        changeDough={changeDough}
        stuffedCrustIsAvailable={stuffedCrustIsAvailable}
        toggleStuffedCrust={toggleStuffedCrust}
        selectStuffedCrust={selectStuffedCrust}
        activeStuffedCrust={activeStuffedCrust}
        stuffedCrustsAvailable={stuffedCrustsAvailable}
        initialStuffedCrust={initialStuffedCrust}
      />
    </div>
  );
}
PizzaCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  alias: PropTypes.string.isRequired,
  customName: PropTypes.string,
  image_cart: PropTypes.string,
  doughTypes: PropTypes.array.isRequired,
  sizes: PropTypes.array.isRequired,
  currentDough: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stuffedCrustIsAvailable: PropTypes.bool,
  stuffedCrustIsAdd: PropTypes.bool,
  activeStuffedCrust: PropTypes.string,
  stuffedCrustsAvailable: PropTypes.array,
  changeDough: PropTypes.func.isRequired,
  changeSize: PropTypes.func.isRequired,
  toggleStuffedCrust: PropTypes.func.isRequired,
  selectStuffedCrust: PropTypes.func.isRequired,
  setCustomName: PropTypes.func.isRequired,
  initialStuffedCrust: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
