import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';

// Components
import Scene from '../../../../components/Scene';
import Heading from '../../../../components/Heading';
import Accordion from '../../../../components/Accordion';
import Button from '../../../../components/Button';
import NavBar from '../../partials/NavBar';
import IngredientCard from '../../partials/IngredientCard';
import HowToAddModal from '../../partials/HowToAddModal';

// Utils
import isomorphicCookies from '../../../../utils/cookie/isomorphicCookies';

// Style
import styles from './SecondScene.styl';

const cx = classNames.bind(styles);

export default function SecondScene(props) {
  const {
    className, ingredients, totalPrice, mod, onNext, onPrev, addPortion,
    removePortion, removeIngredient, isPhone, isTablet, isSauceSelected, sauceCheck,
    isItSauce, selectedIngredients, warning, isItSauceAfterTheOven
  } = props;

  const [hintIsOpen, setHintIsOpen] = useState(false);

  const showHint = () => {
    if (!isomorphicCookies().get('addIngredientsHintHasShown')) {
      setHintIsOpen(true);
      isomorphicCookies().set('addIngredientsHintHasShown', true, { expires: 7 });
    }
  };

  useEffect(() => {
    showHint();
  }, []);

  const hideHint = () => {
    setHintIsOpen(false);
  };

  const handleNext = () => {
    if (sauceCheck()) {
      return onNext();
    }
    return null;
  };

  const renderNavbar = () => {
    return (
      <FormattedMessage id="constructor.scene2.back">
        {title => (
          <NavBar
            className={cx('SecondScene__navbar')}
            backText={title}
            totalPrice={totalPrice}
            onBack={onPrev}
          />
        )}
      </FormattedMessage>
    );
  };

  const renderFooter = () => {
    return (
      <div className={cx('SecondScene__footer')}>
        {!isPhone ? (
          <Button
            className={cx('SecondScene__button', 'SecondScene__button_back')}
            secondary
            onClick={onPrev}
          >
            <FormattedMessage id="button.back" />
          </Button>
        ) : null}
        <Button className={cx('SecondScene__button')} onClick={handleNext}>
          <FormattedMessage id="button.continue" />
        </Button>
      </div>
    );
  };

  return (
    <Scene
      className={cx('SecondScene', className, {
        SecondScene_tablet: isTablet,
        [`SecondScene_${mod}`]: mod
      })}
      renderNavbar={renderNavbar}
      renderFooter={renderFooter}
    >
      <div className={cx('SecondScene__info')}>
        <FormattedMessage id="ingredients.title">
          {title => (
            <Heading className={cx('SecondScene__title')} level={1} tagName="div">
              {title}
            </Heading>
          )}
        </FormattedMessage>
        <div className={cx('SecondScene__image')}>
          <img src={_get(props, 'variation.image_list_webp', '')} alt="" />
        </div>
        <small className={cx('SecondScene__note')}>
          <FormattedMessage id="constructor.note" />
        </small>
      </div>
      <Accordion className={cx('SecondScene__accordion')} selectedIdx={0}>
        {Object.keys(ingredients).map(type => (
          <Accordion.Item
            key={type}
            title={type}
            disabled={isItSauce(type) ? false : !isSauceSelected}
          >
            <div className={cx('SecondScene__ingredients')}>
              {ingredients[type].map(ingredient => (
                <IngredientCard
                  key={ingredient.id}
                  {...ingredient}
                  className={cx('SecondScene__ingredient')}
                  addPortion={addPortion}
                  removePortion={removePortion}
                  onRemove={removeIngredient}
                  isPhone={isPhone}
                  isItSauce={isItSauce}
                  isItSauceAfterTheOven={isItSauceAfterTheOven}
                  selectedIngredients={selectedIngredients}
                  isWarning={!!warning}
                />
              ))}
            </div>
          </Accordion.Item>
        ))}
      </Accordion>
      <HowToAddModal isOpen={hintIsOpen} onClose={hideHint} />
    </Scene>
  );
}
SecondScene.propTypes = {
  className: PropTypes.string,
  ingredients: PropTypes.object,
  totalPrice: PropTypes.number.isRequired,
  mod: PropTypes.string,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  addPortion: PropTypes.func.isRequired,
  removePortion: PropTypes.func.isRequired,
  removeIngredient: PropTypes.func.isRequired,
  isPhone: PropTypes.bool,
  isTablet: PropTypes.bool,
  isSauceSelected: PropTypes.bool,
  sauceCheck: PropTypes.func,
  isItSauce: PropTypes.func,
  selectedIngredients: PropTypes.object.isRequired,
  warning: PropTypes.string.isRequired
};
