import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import FirstScene from '../scenes/FirstScene';
import SecondScene from '../scenes/SecondScene';
import ThirdScene from '../scenes/ThirdScene';
import OrderSteps from '../partials/OrderSteps';
import WarningModal from '../partials/WarningModal';

// Style
import sceneStyles from '../../Scene/Scene.styl';
import styles from './TabletView.styl';

const cx = classNames.bind(styles);
const cxScene = classNames.bind(sceneStyles);

export default function TabletView(props) {

  const { className, totalPrice, warning, clearWarning, } = props

  const [scene, setScene] = useState(1);

  const nextScene = () => {
    setScene(scene + 1 )
  };

  const prevScene = () => {
    setScene(scene - 1 )
  };

    return (
      <div className={cx('Constructor', 'Constructor_tablet', className)}>
        {<OrderSteps className={cx('Constructor__steps')} step={scene} totalPrice={totalPrice} />}
        {scene === 1 && (
          <FirstScene
            {...props}
            className={cxScene('Scene_tablet', 'Scene_tabletFirst')}
            onNext={nextScene}
          />
        )}
        {scene === 2 && (
          <SecondScene
            {...props}
            className={cxScene('Scene_tablet', 'Scene_tabletSecond')}
            mod="tablet"
            onNext={nextScene}
            onPrev={prevScene}
          />
        )}
        {scene === 3 && (
          <ThirdScene
            {...props}
            className={cxScene('Scene_tablet', 'Scene_tabletThird')}
            mod="tablet"
            onNext={nextScene}
            onPrev={prevScene}
          />
        )}
        <WarningModal isOpen={warning} warning={warning} onClose={clearWarning} />
      </div>
    );
}
TabletView.propTypes = {
  className: PropTypes.string,
  variation: PropTypes.object.isRequired,
  ingredients: PropTypes.object,
  addPortion: PropTypes.func.isRequired,
  removePortion: PropTypes.func.isRequired,
  removeIngredient: PropTypes.func.isRequired,
  totalPrice: PropTypes.number,
  warning: PropTypes.string,
  clearWarning: PropTypes.func,
  isItSauce: PropTypes.func,
};
