import React from 'react';
import PropTypes from 'prop-types';

import MobileView from './MobileView';
import DesktopView from './DesktopView';

export default function IncludeIngredient(props) {
  const {
    id, price, count, included, addPortion,
    removePortion, onRemove, isPhone, type
  } = props;

  const handleAddPortion = () => {
    return addPortion(id, included, type);
  };

  const handleRemovePortion = () => {
    return removePortion(id, included);
  };

  const remove = () => {
    return onRemove(id, included);
  };

  const View = isPhone ? MobileView : DesktopView;
  const totalPrice = price * count;

  return (
    <View
      {...props}
      addPortion={handleAddPortion}
      removePortion={handleRemovePortion}
      remove={remove}
      totalPrice={totalPrice}
    />
  );
}
IncludeIngredient.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  included: PropTypes.bool,
  addPortion: PropTypes.func.isRequired,
  removePortion: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isPhone: PropTypes.bool.isRequired,
  type: PropTypes.string,
  isItSauce: PropTypes.func
};
