import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Heading from '../../../Heading';
import Amount from '../../../Amount';
import VariationInfo from '../../../VariationInfo';
import IncludeIngredient from '../IncludeIngredient';

// Style
import styles from './Composition.styl';

const cx = classNames.bind(styles);

export default function Composition(props) {

  const { className, price, old_price, selectedIngredients, addPortion, removePortion,
    removeIngredient, showRemovePortionModal, mod, isPhone, isItSauce, isItSauceAfterTheOven } = props;

  return (
    <div className={cx('Composition', className, { [`Composition_${mod}`]: mod })}>
      <div className={cx('Composition__info')}>
        <Heading className={cx('Composition__title')} level={isPhone ? 1 : 5} tagName="div">
          <FormattedMessage id="constructor.contains" />
        </Heading>
        <div className={cx('Composition__variation')}>
          <VariationInfo
            {...props}
            className={cx('Composition__variation-info')}
            category_id={1}
          />
          <Amount
            className={cx('Composition__variation-price')}
            value={old_price || price}
            newValue={price}
            size={isPhone ? 'l' : 's'}
          />
        </div>
      </div>
      <div className={cx('Composition__ingredients')}>
        {!isPhone ? (
          <Heading
            className={cx(
              'Composition__ingredients-title',
              'Composition__ingredients-title_tablet'
            )}
            level={2}
            tagName="div">
            <FormattedMessage id="ingredients.titleTablet" />
          </Heading>
        ) : (
          <Heading className={cx('Composition__ingredients-title')} level={2} tagName="div">
            <FormattedMessage id="ingredients.title" />
          </Heading>
        )}
        <div className={cx('Composition__ingredients-list')} data-test-id='ingredients_list'>
          {selectedIngredients
            .sort((a, b) => isItSauce(b.type) - isItSauce(a.type))
            .map(ingredient => (
              <IncludeIngredient
                key={ingredient.id}
                {...ingredient}
                showRemovePortionModal={showRemovePortionModal}
                addPortion={addPortion}
                removePortion={removePortion}
                onRemove={removeIngredient}
                isPhone={isPhone}
                isItSauce={isItSauce}
                isItSauceAfterTheOven={isItSauceAfterTheOven}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
Composition.propTypes = {
  className: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  old_price: PropTypes.number,
  selectedIngredients: PropTypes.array.isRequired,
  addPortion: PropTypes.func.isRequired,
  removePortion: PropTypes.func.isRequired,
  removeIngredient: PropTypes.func.isRequired,
  showRemovePortionModal: PropTypes.func,
  mod: PropTypes.string,
  isPhone: PropTypes.bool.isRequired,
  isItSauce: PropTypes.func
};
