import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import Modal from '../../../Modal';
import Heading from '../../../Heading';
import Button from '../../../Button';

// Style
import styles from './HowToAddModal.styl';

const cx = classNames.bind(styles);

function HowToAddModal(props) {
  const { onClose, isOpen, intl } = props;

  return (
    <Modal className={cx('HowToAddModal')} isOpen={isOpen} onClose={onClose}>
      <div className={cx('HowToAddModal__header')}>
        <FormattedMessage id="constructor.addInrgredientsHint.title">
          {title => (
            <Heading className={cx('HowToAddModal__title')} tagName="div">
              {title}
            </Heading>
          )}
        </FormattedMessage>
      </div>
      <div className={cx('HowToAddModal__text')}>
        <FormattedMessage id="constructor.addInrgredientsHint.text" />
      </div>
      <div className={cx('HowToAddModal__image')}>
        <img src={require(`./card_${intl.locale}.png`).default} width="110" height="110" alt={'card'} />
      </div>
      <Button className={cx('HowToAddModal__button')} onClick={onClose}>
        <FormattedMessage id="button.understandably" />
      </Button>
    </Modal>
  );
}

HowToAddModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired
};
export default injectIntl(HowToAddModal);
