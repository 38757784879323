import { createSelector } from 'reselect';

import {
  getDoughTypes,
  getVariationsByDoughPizza,
  getSizes,
  getVariation,
  getStuffedCrustIsAvailable,
  getStuffedCrusts,
} from './utils';

export const getStuffedCrust = (state, props) => props.stuffedCrust || 'none';

export const doughTypesSelector = createSelector(
  (state, props) => props.variations || [],
  getDoughTypes,
);

export const variationsByDoughSelector = createSelector(
  [(state, props) => props.variations || [], (state, props) => props.currentDough],
  getVariationsByDoughPizza,
);

export const sizesSelector = createSelector(variationsByDoughSelector, getSizes);

export const variationSelector = createSelector(
  [variationsByDoughSelector, (state, props) => props.currentSize, getStuffedCrust],
  getVariation,
);

export const stuffedCrustIsAvailableSelector = createSelector(
  [variationsByDoughSelector, (state, props) => props.currentSize],
  getStuffedCrustIsAvailable,
);

export const stuffedCrustsAvailableSelector = createSelector(
  [variationsByDoughSelector, (state, props) => props.currentSize, getStuffedCrust],
  getStuffedCrusts,
);

export const stuffedCrustIsAddSelector = createSelector(
  [getStuffedCrust],
  propGetStuffedCrust => propGetStuffedCrust !== 'none',
);
