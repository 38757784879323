import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';

import Heading from '../../../Heading';
import Amount from '../../../Amount';

import BackIcon from '../../../../icons/back.svg';

import styles from './NavBar.styl';

const cx = classNames.bind(styles);

export default function NavBar(props) {
  const { className, totalPrice, backText, onBack } = props;

  return (
    <div className={cx('NavBar', className)}>
      <Heading className={cx('NavBar__back')} onClick={onBack}>
          <span className={cx('NavBar__back-icon')}>
            <BackIcon width={16} height={16} />
          </span>
        <Heading className={cx('NavBar__back-label')} tagName="span" level={4}>
          {backText}
        </Heading>
      </Heading>
      {totalPrice > 0 && (
        <div className={cx('NavBar__total')}>
          <FormattedMessage id="label.total" />: <Amount value={totalPrice} size="s" />
        </div>
      )}
    </div>
  );
}
NavBar.propTypes = {
  className: PropTypes.string,
  totalPrice: PropTypes.number,
  backText: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired
};
