import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Style
import styles from './PortionSwitcher.styl';

const cx = classNames.bind(styles);

export default function PortionSwitcher(props) {
  const { className, count, onRemove, onAdd, isCrust } = props;

  const handleClick = () => {
    if (count > 1) {
      onRemove();
    } else {
      onAdd();
    }
  };

  return (
    <div className={cx('PortionSwitcher', isCrust ? 'PortionSwitcher__disabled' : className)}>
      <div className={cx('PortionSwitcher__slider')} />
      {(isCrust ? [1] : [1, 2]).map(size => (
        <div data-test-id="multiplier"
          key={size}
          onClick={handleClick}
          className={cx('PortionSwitcher__item', {
            PortionSwitcher__item_active: Number(count) === size
          })}
        >
          &times;{size}
        </div>
      ))}
    </div>
  );
}
PortionSwitcher.propTypes = {
  className: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRemove: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  isCrust: PropTypes.bool.isRequired
};
