import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _isArray from 'lodash/isArray';

// Actions
import {
  addedIngredientsSelector,
  allIngredientsTotalPriceSelector,
  includeIngredientsSelector,
  ingredientsGroupByTypeSelector,
  selectedIngredientsSelector,
  totalCountSelector,
} from '../../state/modules/constructor/selectors';

import {
  doughTypesSelector,
  getStuffedCrust,
  sizesSelector,
  stuffedCrustIsAddSelector,
  stuffedCrustIsAvailableSelector,
  stuffedCrustsAvailableSelector,
  variationSelector,
} from '../../state/modules/catalog/selectors';

// Components
import CreatePizzaSuccessMessage from '../CreatePizzaSuccessMessage';
import MobileView from './MobileView';
import TabletView from './TabletView';
import DesktopView from './DesktopView';
import { CRUST_EXTRA, CRUST_EXTRA_EN, CRUST_EXTRA_PL } from '../../utils/constants';

class Constructor extends Component {
  static defaultProps = {
    className: '',
    customName: '',
    added: false,
    variation: {},
    addedIngredients: [],
    includeIngredients: [],
    isPhone: false,
    removedIngredientsIds: [],
    currentDough: 0,
    initialStuffedCrust: '',
  };

  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    customName: PropTypes.string,
    added: PropTypes.bool,
    variation: PropTypes.object,
    doughTypes: PropTypes.array.isRequired,
    currentDough: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    addedIngredients: PropTypes.array,
    includeIngredients: PropTypes.array,
    totalCount: PropTypes.number.isRequired,
    changeDough: PropTypes.func.isRequired,
    changeSize: PropTypes.func.isRequired,
    toggleStuffedCrust: PropTypes.func.isRequired,
    selectStuffedCrust: PropTypes.func.isRequired,
    addPortion: PropTypes.func.isRequired,
    removePortion: PropTypes.func.isRequired,
    removeIngredient: PropTypes.func.isRequired,
    setCustomName: PropTypes.func.isRequired,
    addCartItem: PropTypes.func.isRequired,
    ingredientsTotalPrice: PropTypes.number.isRequired,
    removedIngredientsIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    initialStuffedCrust: PropTypes.string,
    isTablet: PropTypes.bool,
    isPhone: PropTypes.bool,
    selectedIngredients: PropTypes.array,
    isSauceSelected: PropTypes.bool,
    isItSauce: PropTypes.func,
    isItSauceAfterTheOven: PropTypes.func,
    isSauceExist: PropTypes.bool,
    isSauceAfterTheOvenExist: PropTypes.bool,
    isSauceAfterTheOvenSelected: PropTypes.bool,
  };

  state = {
    warning: '',
  };

  componentDidMount() {
    const {
      removedIngredientsIds,
      currentDough,
      doughTypes,
      initialStuffedCrust,
      totalCount,
      isSauceExist,
      isSauceAfterTheOvenExist,
      selectedIngredients,
    } = this.props;

    // если не выбрано тесто, то устанавливаем первое попавшееся
    if (!currentDough) {
      this.changeDough(doughTypes?.[0]?.id);
    }

    // если не выбран борт, то делаем то же самое
    if (initialStuffedCrust) {
      this.selectStuffedCrust(initialStuffedCrust);
    }
    // Удаляем ингредиенты, которые убрали через карточку товара
    if (removedIngredientsIds) {
      if (_isArray(removedIngredientsIds)) {
        removedIngredientsIds.map(id => this.removeIngredient(id, true));
      } else {
        this.removeIngredient(removedIngredientsIds, true);
      }
    }
    // добавляем корочку, если добавили её в карточке товара
    const addedCrust = selectedIngredients.find(
      ingredient => ingredient.type === CRUST_EXTRA || ingredient.type === CRUST_EXTRA_EN || ingredient.type === CRUST_EXTRA_PL);
    if (addedCrust) {
      this.addPortion(addedCrust.id, true, CRUST_EXTRA);
    }
    const value = 10 + (isSauceExist && isSauceAfterTheOvenExist ? 2 : isSauceExist || isSauceAfterTheOvenExist ? 1 : 0);

    return this.setWarning(totalCount >= value ? 'constructor.message.moreThan' : '');
  }

  componentDidUpdate(prevProps) {
    const {
      variation, selectedIngredients, ingredients, isSauceExist, isSauceAfterTheOvenExist,
    } = this.props;

    const prevVariation = prevProps.variation;
    const prevSelectedIngredients = prevProps.selectedIngredients;
    const prevTotalCount = prevProps.totalCount;

    //если переходим через кнопку "создай свою пиццу" нужна проверка на prevVariation
    if (prevVariation.id && prevProps.variation.id !== variation.id) {
      this.setWarning('');
      //достаем из ассоциативного массива ingredients полный список возможных ингредиентов
      const totalIngredients = Object.values(ingredients).flat(2);
      //пробегаемся по массиву ингредиентов делая все возможные проверки на удаление/добавление
      totalIngredients.forEach(ingredient => {
        const value = 10 + (isSauceExist && isSauceAfterTheOvenExist ? 2 : isSauceExist || isSauceAfterTheOvenExist ? 1 : 0);
          const currentIngr = variation.available_ingredients?.find(ingr => ingr.name === ingredient.name);
          const prevIngr = prevVariation.available_ingredients?.find(ingr => ingr.name === ingredient.name);
          const selectedIngr = selectedIngredients?.find(ingr => ingr.name === ingredient.name);
          const prevSelectedIngr = prevSelectedIngredients?.find(ingr => ingr.name === ingredient.name);

          if (prevSelectedIngr && prevIngr && prevSelectedIngr.count === 1 &&
            prevTotalCount && prevTotalCount <= value && currentIngr && !selectedIngr) {
            const included = variation?.include_ingredients?.some(ingredient =>
              ingredient.id === currentIngr.id);
            this.addPortion(currentIngr.id, included, currentIngr.type);
          }
          if (prevSelectedIngr && prevIngr && prevSelectedIngr.count === 2 && currentIngr
            && selectedIngr && selectedIngr.count === 1 && prevTotalCount && prevTotalCount <= value) {
            const included = variation?.include_ingredients?.some(ingredient =>
              ingredient.id === currentIngr.id);
            this.addPortion(currentIngr.id, included, currentIngr.type);
          }
          if (prevSelectedIngr && prevIngr && prevSelectedIngr.count === 2 && currentIngr
            && !selectedIngr && prevTotalCount && prevTotalCount <= value) {
            const included = variation?.include_ingredients?.some(ingredient =>
              ingredient.id === currentIngr.id);
            this.addPortion(currentIngr.id, included, currentIngr.type);
            this.addPortion(currentIngr.id, included, currentIngr.type);
          }
          if (!prevSelectedIngr && currentIngr && selectedIngr && selectedIngr.count === 1) {
            const included = variation?.include_ingredients?.some(ingredient =>
              ingredient.id === selectedIngr.id);
            this.removeIngredient(selectedIngr.id, included);
          }
          if (!prevSelectedIngr && currentIngr && selectedIngr && selectedIngr.count === 2) {
            const included = variation?.include_ingredients?.some(ingredient =>
              ingredient.id === selectedIngr.id);
            this.removeIngredient(selectedIngr.id, included);
            this.removeIngredient(selectedIngr.id, included);
          }
          if (prevSelectedIngr && prevIngr && prevSelectedIngr.count === 1 && currentIngr &&
            selectedIngr && selectedIngr.count === 2) {
            const included = variation?.include_ingredients?.some(ingredient =>
              ingredient.id === selectedIngr.id);
            this.removeIngredient(selectedIngr.id, included);
          }
        },
      );
    }
  }

  /**Меняем название пиццы */
  setCustomName = name => {
    const { setCustomName, id } = this.props;

    return setCustomName(id, name);
  };

  /**Меняем тесто */
  changeDough = dough => {
    const { changeDough, id } = this.props;

    return changeDough(id, dough);
  };

  /**Меняем размер */
  changeSize = size => {
    const { changeSize, id } = this.props;

    return changeSize(id, size);
  };

  /**Переключаем борт */
  toggleStuffedCrust = value => {
    const { toggleStuffedCrust, id } = this.props;

    return toggleStuffedCrust(id, value);
  };

  /**Выбираем борт */
  selectStuffedCrust = value => {
    const { selectStuffedCrust, id } = this.props;

    return selectStuffedCrust(id, value);
  };

  /**Добавляем 1 шт ингредиента */
  addPortion = (ingredientId, included, type) => {
    const {
      isSauceSelected,
      totalCount,
      addPortion,
      id,
      variation,
      isItSauce,
      isItSauceAfterTheOven,
      isSauceExist,
      isSauceAfterTheOvenExist,
      isSauceAfterTheOvenSelected
    } = this.props;

    const isCrust = type === CRUST_EXTRA || type === CRUST_EXTRA_EN || type === CRUST_EXTRA_PL;
    // нельзя добавить больше одного соуса
    if (isItSauce(type) && isSauceSelected) {
      return this.setWarning('constructor.message.sauceRequirment');
    }

    if (isItSauceAfterTheOven(type) && isSauceAfterTheOvenSelected) {
      return this.setWarning('constructor.message.sauceRequirment');
    }

    if (isCrust) {
      return addPortion(id, variation.id, ingredientId, included);
    }

    const value = 10 + (isSauceExist && isSauceAfterTheOvenExist ? 2 : isSauceExist || isSauceAfterTheOvenExist ? 1 : 0);

    return totalCount >= value
      ? this.setWarning('constructor.message.moreThan')
      : addPortion(id, variation.id, ingredientId, included);
  };

  /**Удаляем 1 шт ингредиента */
  removePortion = (ingredientId, included) => {
    const { removePortion, id, variation } = this.props;

    this.setWarning('');

    return removePortion(id, variation.id, ingredientId, included);
  };

  /**Удаляем ингредиент целиком */
  removeIngredient = (ingredientId, included) => {
    const { id, variation, removeIngredient } = this.props;

    const isCrust = variation.available_ingredients.some(
      ingr =>
        (ingr.type === CRUST_EXTRA || ingr.type === CRUST_EXTRA_EN || ingr.type === CRUST_EXTRA_PL) && ingr.id === ingredientId,
    );

    if (!isCrust) {
      this.setWarning('');
    }

    return removeIngredient(id, variation.id, ingredientId, included);
  };

  /**Добавляем пиццу в корзину */
  onAddToCart = async () => {
    const {
      includeIngredients,
      customName,
      variation,
      addedIngredients,
      addCartItem,
      isSauceSelected,
      id,
      setIsAdded,
    } = this.props;

    const addedIncludeIngredients = includeIngredients.filter(
      ingredient => Number(ingredient.count) > 1,
    );

    const removedIngredients = includeIngredients.filter(
      ingredient => Number(ingredient.count) === 0,
    );

    const composition = [
      {
        type: 'good',
        name: customName,
        item: {
          ...variation,
          added_ingredients: [...addedIncludeIngredients, ...addedIngredients],
          removed_ingredients: removedIngredients,
        },
      },
    ];

    // если не выбран соус, то нельзя добавить заказ
    if (!isSauceSelected) {
      return this.setWarning('constructor.message.sauceRequirment');
    }

    const onSuccessCallback = () => {
      setIsAdded(id);
    };

    return addCartItem({ composition }, onSuccessCallback);
  };

  //

  /**Выводим текст предупреждения */
  setWarning = value => {
    this.setState({ warning: value });
  };

  clearWarning = () => {
    this.setState({ warning: '' });
  };

  sauceCheck = () => {
    const { isSauceSelected } = this.props;

    if (!isSauceSelected) {
      this.setState({ warning: 'constructor.message.sauceRequirment' });

      return false;
    }

    return true;
  };

  render() {
    const {
      variation,
      ingredientsTotalPrice,
      isPhone,
      added,
      customName,
      name,
      isTablet,
      isSauceSelected,
      selectedIngredients,
      mode,
    } = this.props;
    const { warning } = this.state;
    // mode === 'productCard' ? DesktopView :
    const totalPrice = Number(variation.price) + ingredientsTotalPrice;
    const View = mode === 'productCard' ? DesktopView : isPhone ? MobileView : isTablet ? TabletView : DesktopView;
    return added ? (
      <CreatePizzaSuccessMessage name={customName || name} mod={isTablet ? 'tablet' : ''} />
    ) : (
      <View
        {...this.props}
        changeSize={this.changeSize}
        changeDough={this.changeDough}
        addPortion={this.addPortion}
        removePortion={this.removePortion}
        removeIngredient={this.removeIngredient}
        addToCart={this.onAddToCart}
        setCustomName={this.setCustomName}
        toggleStuffedCrust={this.toggleStuffedCrust}
        selectStuffedCrust={this.selectStuffedCrust}
        clearWarning={this.clearWarning}
        warning={warning}
        isSauceSelected={isSauceSelected}
        sauceCheck={this.sauceCheck}
        totalPrice={totalPrice}
        selectedIngredients={selectedIngredients}
      />
    );
  }
}

export default connect((state, props) => {
  const { isPhone, isTablet } = state.responsive;

  const ingredients = ingredientsGroupByTypeSelector(state, props);
  const selectedIngredients = selectedIngredientsSelector(state, props);
  const isSauceExist =
    Object.keys(ingredients).filter(ingredient => props.isItSauce(ingredient)).length > 0;
  const isSauceAfterTheOvenExist =
    Object.keys(ingredients).filter(ingredient => props.isItSauceAfterTheOven(ingredient)).length > 0;
  const isSauceSelected = isSauceExist
    ? selectedIngredients.filter(ingredient => props.isItSauce(ingredient.type)).length > 0
    : true;
  const isSauceAfterTheOvenSelected = isSauceAfterTheOvenExist
    ? selectedIngredients.filter(ingredient => props.isItSauceAfterTheOven(ingredient.type)).length > 0
    : true;

  return {
    selectedIngredients,
    isSauceSelected,
    isSauceAfterTheOvenSelected,
    isSauceExist,
    isSauceAfterTheOvenExist,
    isPhone,
    isTablet,
    ingredients,
    doughTypes: doughTypesSelector(state, props),
    sizes: sizesSelector(state, props),
    stuffedCrustIsAvailable: stuffedCrustIsAvailableSelector(state, props),
    variation: variationSelector(state, props),
    stuffedCrustsAvailable: stuffedCrustsAvailableSelector(state, props),
    activeStuffedCrust: getStuffedCrust(state, props),
    stuffedCrustIsAdd: stuffedCrustIsAddSelector(state, props),
    includeIngredients: includeIngredientsSelector(state, props),
    addedIngredients: addedIngredientsSelector(state, props),
    ingredientsTotalPrice: allIngredientsTotalPriceSelector(state, props),
    totalCount: totalCountSelector(state, props),
  };
})(Constructor);
