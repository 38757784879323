import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Amount from '../../../../Amount';
import PortionSwitcher from '../../PortionSwitcher';

// Icons
import RemoveIcon from '../../../../../icons/close.svg';

// Style
import styles from './DesktopView.styl';
import { CRUST_EXTRA, CRUST_EXTRA_EN, CRUST_EXTRA_PL } from '../../../../../utils/constants';

const cx = classNames.bind(styles);

export default function DesktopView(props) {

  const {
    className, name, count, addPortion, removePortion,
    remove, totalPrice, type, isItSauce, isItSauceAfterTheOven, not_for_delete = false,
  } = props;

  const isCrust = type === CRUST_EXTRA || type === CRUST_EXTRA_EN || type === CRUST_EXTRA_PL;
  const isUndeleted = not_for_delete;
  return (
    <div className={cx('IncludeIngredient', className)}>
      <div className={cx('IncludeIngredient__cell', 'IncludeIngredient__cell_name')}>
        <div className={cx('IncludeIngredient__name')} data-test-id="ingredient_name">{name}</div>
      </div>
      {!isItSauce(type) && !isItSauceAfterTheOven(type) && (
        <div className={cx('IncludeIngredient__cell', 'IncludeIngredient__cell_portion')}>
          <PortionSwitcher
            count={count}
            onAdd={addPortion}
            onRemove={removePortion}
            isCrust={isCrust}
          />
        </div>
      )}
      <div className={cx('IncludeIngredient__cell', 'IncludeIngredient__cell_price')}>
        <Amount size="s" className={cx('IncludeIngredient__amount')} value={totalPrice} />
      </div>
      {!isUndeleted ?
        <div
          className={cx('IncludeIngredient__cell', 'IncludeIngredient__cell_remove')}
          onClick={remove}
        >
          <div className={cx('IncludeIngredient__remove')}>
            <RemoveIcon width={10} height={10} />
          </div>
        </div>
        : <div
          className={cx('IncludeIngredient__cell', 'IncludeIngredient__cell_empty')}
        >
        </div>
      }
    </div>
  );
}
DesktopView.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  addPortion: PropTypes.func.isRequired,
  removePortion: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  totalPrice: PropTypes.number,
  type: PropTypes.string,
  isItSauce: PropTypes.func,
};
