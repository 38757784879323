import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Amount from '../../../Amount';

// Icons
import CheckIcon from '../../../../icons/check.svg';

// Style
import styles from './OrderSteps.styl';

const cx = classNames.bind(styles);

export default function OrderSteps(props) {

  const { className, totalPrice, step } = props;

  return (
    <div className={cx('OrderSteps', className)}>
      <ul className={cx('OrderSteps__list')}>
        <FormattedMessage id="constructor.scene2.back">
          {title => (
            <li
              className={cx('OrderSteps__list-item', {
                'OrderSteps__list-item_active': step === 1,
                'OrderSteps__list-item_completed': step > 1
              })}>
              {title}
              {step > 1 && (
                <span className={cx('OrderSteps__icon')}>
                    <CheckIcon width={16} height={16} />
                  </span>
              )}
            </li>
          )}
        </FormattedMessage>
        <FormattedMessage id="constructor.scene2.title">
          {title => (
            <li
              className={cx('OrderSteps__list-item', {
                'OrderSteps__list-item_active': step === 2,
                'OrderSteps__list-item_completed': step > 2
              })}>
              {title}
              {step > 2 && (
                <span className={cx('OrderSteps__icon')}>
                    <CheckIcon width={16} height={16} />
                  </span>
              )}
            </li>
          )}
        </FormattedMessage>
        <FormattedMessage id="orderHistory.orderId">
          {title => (
            <li
              className={cx('OrderSteps__list-item', {
                'OrderSteps__list-item_active': step === 3
              })}>
              {title}
            </li>
          )}
        </FormattedMessage>
      </ul>
      {totalPrice > 0 && (
        <div className={cx('OrderSteps__total')}>
          <FormattedMessage id="label.total" />: <Amount value={totalPrice} size="s" />
        </div>
      )}
    </div>
  );
}
OrderSteps.propTypes = {
  className: PropTypes.string,
  totalPrice: PropTypes.number,
  step: PropTypes.number
};
