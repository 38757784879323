import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Heading from '../Heading';
import ToggleArrow from '../ToggleArrow';

// Styles
import styles from './Accordion.styl';

const cx = classNames.bind(styles);

export default function Item(props) {
  const { className, selected, title, subtitle, divider, children, disabled, handleClick } = props;

  return (
    <div
      className={cx('Accordion__item', className, {
        Accordion__item_selected: selected,
        Accordion__item_disabled: disabled,
      })}
    >
      <div className={cx('Accordion__toggle')} onClick={handleClick}>
        <Heading className={cx('Accordion__toggle-title')} tagName="div" level={3}>
          {title}
        </Heading>
        {selected && subtitle && (
          <div className={cx('Accordion__toggle-subtitle')} level={4}>
            {subtitle}
          </div>
        )}
        <ToggleArrow className={cx('Accordion__toggle-arrow')} isOpen={selected} />
      </div>
      {selected && (
        <div className={cx('Accordion__content')}>
          {divider && <div className={cx('Accordion__divider')} />}
          {children}
        </div>
      )}
    </div>
  );
}

Item.propTypes = {
  handleClick: PropTypes.func,
  index: PropTypes.number,
  className: PropTypes.string,
  selected: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  divider: PropTypes.any,
  children: PropTypes.any,
  disabled: PropTypes.bool,
};
