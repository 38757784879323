import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Heading from '../../../Heading';
import Amount from '../../../Amount';
import RemovePortionModal from '../RemovePortionModal';

// Icons
import RemoveIcon from '../../../../icons/delete.svg';

// Style
import styles from './IngredientCard.styl';

// Constants
import { CRUST_EXTRA, CRUST_EXTRA_EN, CRUST_EXTRA_PL } from '../../../../utils/constants';

const cx = classNames.bind(styles);

export default function IngredientCard(props) {
  const {
    className, id, name, image, price, count, included, addPortion, removePortion,
    onRemove, isPhone, type, isItSauce, selectedIngredients, isWarning, isItSauceAfterTheOven, not_for_delete = false,
  } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const isUndeleted = not_for_delete;

  const showRemoveModal = () => {
    setModalIsOpen(true);
  };

  const hideRemoveModal = () => {
    setModalIsOpen(false);
  };

  const handleAddPortion = () => {
    return addPortion(id, included, type);
  };

  const handleRemovePortion = () => {
    return removePortion(id, included);
  };

  const remove = () => {
    return onRemove(id, included);
  };


  const getButton = isCrustIncluded => {
      const hasCount = count > 0;
      // любой ингредиент можно добавить 2 раза, кроме соуса
      const value = (isItSauce(type) || isItSauceAfterTheOven(type)) ? 1 : 2;
      const isCrust = type === CRUST_EXTRA || type === CRUST_EXTRA_EN || type === CRUST_EXTRA_PL;

      if (isPhone) {
        if (!isUndeleted && hasCount) {
          return (
            <div
              className={cx('IngredientCard__action', 'IngredientCard__action_remove')}
              onClick={showRemoveModal}
            >
              <RemoveIcon width={24} height={24} />
            </div>
          );
        }
        if (isUndeleted && count > 1) {
          return (
            <div
              className={cx('IngredientCard__action', 'IngredientCard__action_remove')}
              onClick={showRemoveModal}
            >
              <RemoveIcon width={24} height={24} />
            </div>
          );
        }
      }
      if ((!isCrust && isWarning) || (isCrust && isCrustIncluded)) {
        return null;
      }

      if (count < value) {
        return (
          <div
            className={cx('IngredientCard__action', 'IngredientCard__action_add')}
            onClick={handleAddPortion}
          />
        );
      }


      return (
        <div
          className={cx('IngredientCard__action', 'IngredientCard__action_remove')}
          onClick={handleRemovePortion}
        >
          <RemoveIcon width={24} height={24} />
        </div>
      );
    }
  ;

  const handleClick = () => {
    const value = (isItSauce(type) || isItSauceAfterTheOven(type)) ? 0 : 1;

    if (count > value) {
      handleRemovePortion();
    } else {
      handleAddPortion();
    }
  };

  const hasCount = count > 0;
  const isCrustIncluded =
    (type === CRUST_EXTRA || type === CRUST_EXTRA_EN || type === CRUST_EXTRA_PL) &&
    selectedIngredients.some(el => el.type === CRUST_EXTRA || el.type === CRUST_EXTRA_EN || el.type === CRUST_EXTRA_PL);
  return (
    <div
      className={cx('IngredientCard', className, {
        IngredientCard_included: hasCount,
      })}
    >
      {hasCount && !isItSauce(type) && !isItSauceAfterTheOven(type) && (
        <Heading className={cx('IngredientCard__count')} level={5} tagName="div">
          &times;{count}
        </Heading>
      )}
      <div
        className={cx('IngredientCard__body')}
        onClick={isCrustIncluded ? null : isPhone ? handleAddPortion : handleClick}
      >
        <figure className={cx('IngredientCard__image')}>
          <img src={image} alt={'ingredient'} />
        </figure>
        <Heading className={cx('IngredientCard__name')} level={5}>
          {name}
        </Heading>
        <Amount className={cx('IngredientCard__price')} value={price} size="s" />
      </div>
      {getButton(isCrustIncluded)}
      {!isUndeleted && hasCount && !isPhone && !isItSauce(type) && !isItSauceAfterTheOven(type) && (
        <div className={cx('IngredientCard__remove')} onClick={remove}>
          <RemoveIcon width={24} height={24} />
        </div>
      )}
      {isPhone && (
        <RemovePortionModal
          {...props}
          isOpen={modalIsOpen}
          onRemove={remove}
          onRemovePortion={handleRemovePortion}
          onClose={hideRemoveModal}
        />
      )}
    </div>
  );
};

IngredientCard.defaultProps = {
  className: '',
  count: '0',
  included: false,
  isPhone: false,
};

IngredientCard.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  included: PropTypes.bool,
  addPortion: PropTypes.func.isRequired,
  removePortion: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isPhone: PropTypes.bool,
  type: PropTypes.string.isRequired,
  isItSauce: PropTypes.func.isRequired,
  selectedIngredients: PropTypes.object.isRequired,
  isWarning: PropTypes.bool.isRequired,
};
