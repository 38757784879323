import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Scene from '../../../../components/Scene';
import Heading from '../../../../components/Heading';
import Button from '../../../../components/Button';
import NavBar from '../../partials/NavBar';
import PizzaCard from '../../partials/PizzaCard';

// Router
import { paths, getRoute } from '../../../../entry/routes';

// Style
import styles from './FirstScene.styl';

const cx = classNames.bind(styles);

export default function FirstScene(props) {

  const { className, variation, history, totalPrice, onNext, isTablet } = props;

  const back = () => {
    history.push(getRoute(paths.home));
  };

  const renderNavbar = () => {
    return (
      <FormattedMessage id="link.goToHome">
        {title => (
          <NavBar
            className={cx('FirstScene__navbar')}
            backText={title}
            totalPrice={totalPrice}
            onBack={back}
          />
        )}
      </FormattedMessage>
    );
  };

  const renderFooter = () => {
    return (
      <div className={cx('FirstScene__footer')}>
        <Button className={cx('FirstScene__button')} onClick={onNext}>
          <FormattedMessage id="constructor.button.changeIngredients" />
        </Button>
      </div>
    );
  };

  return (
    <Scene
      className={cx('FirstScene', className, {
        FirstScene_tablet: isTablet
      })}
      renderNavbar={renderNavbar}
      renderFooter={renderFooter}
    >
      <div className={cx('FirstScene__body')}>
        <Heading className={cx('FirstScene__title')} level={1} tagName="h1">
          {/*<FormattedMessage id="constructor.title" />*/}
        </Heading>
        <PizzaCard {...variation} {...props} className={cx('FirstScene__pizza')} />
      </div>
    </Scene>
  );
}
FirstScene.propTypes = {
  className: PropTypes.string,
  variation: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  totalPrice: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  isTablet: PropTypes.bool
};
